import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // 路由重定向
  { path: '/', redirect: '/menu' },
  // 登录
  {
    path: '/login', component: () => import("@/views/login.vue")
  },
	{
	  path: '/changePassword', component: () => import('@/views/changePassword.vue')
	},
  {
    path: '/menu', component: () => import('@/views/menu.vue'),
    children: [
      {
        path: '/', component: () => import('@/views/home.vue')
      },
			// 平台订单
			// 速卖通
			{
			  path: '/aliExpress', component: () => import('@/views/platformOrder/aliExpress.vue')
			},
			
			//KSA
			{
			  path: '/merchandiseList', component: () => import('@/views/Fulfillment/merchandiseList.vue')
			},
			{
			  path: '/Inventory', component: () => import('@/views/Fulfillment/Inventory.vue')
			},
			{
			  path: '/inscanLogs', component: () => import('@/views/Fulfillment/inscanLogs.vue')
			},
			{
			  path: '/outscanLogs', component: () => import('@/views/Fulfillment/outscanLogs.vue')
			},
			{
			  path: '/FBAOrder', component: () => import('@/views/Fulfillment/FBAOrder.vue')
			},
			{
			  path: '/B2COrderImport', component: () => import('@/views/Fulfillment/B2COrderImport.vue')
			},
			// RTO
			{
			  path: '/RtoBind', component: () => import('@/views/RTO/RtoBind.vue')
			},
			{
			  path: '/RtoFbx', component: () => import('@/views/RTO/RtoFbx.vue')
			},
			{
			  path: '/RtoDamage', component: () => import('@/views/RTO/RtoDamage.vue')
			},
			//China
			// 菜单调整 2024-01-22 zpy
			// 空运订单
			{
				path: '/B2BAirOrderList', component: () => import('@/views/China/airOrder/index.vue')
			},
			{
			  path: '/B2COrderList', component: () => import('@/views/China/B2COrderList.vue')
			},
			
			// 海运订单
			{
				path: '/B2BSeaOrderList', component: () => import('@/views/China/seaOrder/index.vue')
			},
			
			{
			  path: '/B2BOrderList', component: () => import('@/views/China/B2BOrderList.vue')
			},
			{
			  path: '/overseaLocation', component: () => import('@/views/Fulfillment/overseaLocation.vue')
			},
			{
				// 2022-1-20 清单模板下载
			  path: '/mouldList', component: () => import('@/views/mouldList.vue')
			},
			{
				//客户信息
			  path: '/customerInfo', component: () => import('@/views/customerInfo.vue')
			},
      {
        path: '/Tracking', component: () => import('@/views/Tracking.vue')
      }, 
	  {
		path: '/inscanLog', component: () => import('@/views/Logs/inscanLog.vue')
	  },
	  {
		path: '/outscanLog', component: () => import('@/views/Logs/outscanLog.vue')
	  }
    ]
  }
]



const router = new VueRouter({
  routes
})

export default router
