import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: null, // 初始化token
		rtoData:{
			amount:0,// RTO未处理订单总数
			b2cQty:0,// RTO B2C 未处理订单数量
			fbxQty:0,// RTO FBX 未处理订单数量
		}
	},
	mutations: {
		// 存储token
		setToken(state, token) {
			state.token = token
		},
		// 存储当前客户未处理RTO订单信息
		setRTOOrderInfo(state,info) {
			state.rtoData = info;
		}
	},
	getters: {
		//获取token方法
		//判断是否有token,如果没有重新赋值，返回给state的token
		getToken(state) {
			if (!state.token) {
				state.token = sessionStorage.getItem("token");
			}
			return state.token
		},
	},
})
