// 该文档为VIP国际化-中文
const en = {
	// 提示信息
	tipsInfo:{
		noData:'No Data',
		uploading:'Uploading',
		uploadExcelTypeTips:'Upload files can only be of type XLS and XLSX ！',
		uploadSuccess:'Upload Success',
		uploadFailed:'Upload Failed',
		loadingText:'Loading...',
		generating:'Generating',
		exportSuccess:'Export Success',
		downloadSuccess:'Download Success',
		exportCancel:'Export Cancel',
		exportFailed:'Export Failed',
		downloadTips:'Downloading,Please Wait',
		loginOutTips:'Login Timeout,Please Login Again',
		errorPassword:'Password Error, Please Re-enter',
		noPodInfo:'No POD Info',
		// 输入框或者选择框提示信息 结合使用
		inputPlaceholder:'Please Input ',
		selectPlaceholder:'Please Choose ',
		nullPlaceholder:' Cannot Be Empty',
		inputTips:'Please Input Infomation',
		
		orderNoExistTips:'The order number entered does not exist',
		createOrderNoFailedTips:'Create Order No Failed',
		//删除
		deleteRowTips:'Are you sure to delete the current row data ?',
		deleteAllRowTips:'Are you sure to delete all row data ?',
		deleteSuccessTips:'Delete Success',
		
		copyTips:'Copy This Column Info',
		copySuccess:'Copy Success',
		copyFail:'Copy Failed',
		
		submitOrderFBXFileTips:'Please Upload FBX File First',
		submitOrderPDFFileTips:'Please Upload PDF File First',
		submitOrderDeliveryFileTips:'Please Upload Delivery File First',
		submitOrderDeliveryMarkTips:'Please Upload Delivery File First',
		submitOrderSkuListFileTips:'Please Upload Sku List File First',
		submitOrderPacketFileTips:'Please Upload Packet File First',
		
		selectOrderTips:'Please select order first',
		importTips:'Choose File To Import',
		openDialogTips:'Open Operation Dialog',
		selectRowTips:'Select Row On Table To Operate',
		selectCartonTips:'Select Row On Table To Operate',
		
		exportDatabySearch:'Export data by search result',
		uploadFileNameLimitTips:'The file name is too long，please upload again',
		
		tip:'Tips',
		warning:'Warning',
		info:'Info',
		success:'Success',
		operationSuccess:'Operation Success',
		failed:'Failed',
	},
	// 通用信息
	commonInfo:{
		languageZh:'Chinese',
		languageEn:'English',
		// 共用搜索条件按钮 
		searchBtn:'Search',
		refreshBtn:'Refresh',
		createBtn:'Create',
		action:'Action',
		addBtn:'Add',
		yesBtn:'Yes',
		noBtn:'No',
		deleteBtn:'Delete',
		deleteAllBtn:'Delete All',
		upload:'Upload',
		uploadAgain:'Re-upload',
		uploadExcel:'Upload Excel',
		beginTime:'StartTime',
		endTime:'EndTime',
		exportExcel:'Export Excel',
		total:'Total',
		secondTotal:'Total',
		case:'Box',
		result:'Result',
		reason:'Reason',
		link:'Link',
		time:'Time',
		photo:'Photo',
		appointmentTime:'Appointment Time',
		choicePicture:'choicePicture',
		city:'City',
		noInfo:'Null',
		checkFailedReason:'Check Failed Reason',
		reviewRemark:'Review remark',
		// 共用 表格列信息
		orderInfo:'Order Info',
		orderList:'Order List',
		orderDeList:'Order Details List',
		platformOrderNoColumn:'Platform Order No',
		clientOrderNoColumn:'Client Order No',
		orderNoColumn:'Order No',
		subOrderNo:'SubOrder No',
		createOrder:'Create Order',
		createAirOrder:'Create Air Order',
		batchCreateAirOrder:'Batch Create Order',
		createSeaOrder:'Create Sea Order',
		modifyAirOrder:'Modify Air Order',
		modifySeaOrder:'Modify Sea Order',
		modifyOrder:'Modify Order',
		initTime:'InitTime',
		inventoryTime:'InitTime',
		deliveryTime:'DeliveryTime',
		createTimeColumn:'CreateTime',
		operationColumn:'Action',
		statusColumn:'Status',
		orderType:'Order Type',
		actionDetail:'Detail',
		actionCheckReason:'Check Reason',
		orderDetails:'Detail',
		actiontrack:'Tracking',
		actionCheckFile:'Check File',
		batchOperation:'Batch Operation',
		trackingTitle:'FLEETAN Tracking',
		actionCheckProduct:'Check Product',
		trackLocation:'Location',
		actionCheckPOD:'Check POD',
		actionModify:'Modify',
		actionModifyDeliveryTime:'Modify Delivery Time',
		actionDownload:'Download',
		faceNoodles:"Download",
		awbGenerating:'Generating...',
		actionDownloadPDF:'Download PDF',
		downloadBoxStickers:'Download PDF',
		boxStickersGenerating:'Generating...',
		actionDownloadSkuList:'Download SKU List',
		actionDownloadSeaSkuTemplate:'Download SKU Template',
		downloadClearanceFile:'Download Clearance File',
		newLabelFile:'New Label File',
		orderTrack:'Order Track Information',
		moreAction:'More Action',
		viewMore:'View More',
		
		packageNo:'Carton No',
		index:'Index',
		dlvdPackageNo:'FLEETAN Carton No',
		length:'Length',
		breadth:'Breadth',
		height:'Height',
		weight:'Weight',
		weightKG:'Weight(KG)',
		packageAmount:'Package Amount',
		packageWeight:'Package Weight',
		grossWeight:'GrossWeight',
		retreatWeight:'Retreat Weight',
		secondWeight:'Weight',
		chargeWeight:'ChargeWeight',
		chargeVolume:'volume',
		chargeVolumeCbm:'volume(CBM)',
		productAmount:'Total Goods/Box',
		memo:'Memo',
		remark:'Remark',
		consignor:'Consignor',
		consignee:'Consignee',
		contactMan:'Contacts',
		contact:'Contact',
		address:'Address',
		deliveryAddress:'Receiving address',
		deliveryPincode:'Pincode',
		payMentMode:'PayMent Mode',
		amountToCollect:'Payment Amount',
		city:'City',
		country:'Country',
		deliveryWarehouse:'Warehouse',
		cnConsignorWhHouse:'CN Warehouse',
		warhouseCode:'Warhouse Code',
		customeWarehouse:'Custom Warehouse',
		deliveryCountry:'Country',
		deliveryType:'Delivery Type',
		deliveryMode:'Delivery Mode',
		homeDeliveryService:'Home Delivery Service',
		whSelfPickup:'WH Self-Pickup',
		selfPickup:'Self-Pickup',
		consigneeTypeOne:'Consignee',
		consigneeContact:'Contact',
		deliveryDest:'Address',
		fbaNo:'FBA No',
		asnNo:'ASN No',
		clientDeliveryNo:'Client Delivery No',
		deliveryDate:'Appointment Time',
		pickupDate:'Pickup Time',
		fleetanAppointment:'Fleetan Appointment',
		expectedDeliveryQuantity:'Expected Delivery Quantity',
		fbaNoCode:'FBA No Code',
		fbaNoCodeLimit:'Length Is 8 Bits',
		fbaNoLimit:'Maximum Length is 20 Bits',
		clientAwbNoLimit:'Length Is 20 Bits',
		companyLimit:'Length is 5 bits or 6 bits',
		skuCount:'Sku Count',
		packageCount:'Package Count',
		declaredValue:'Declared Amount',
		fleetanWarehouse:'Fleetan Warehouse',
		skuStockType:'Sku Stock',
		cartonStockType:'Carton Stock',
		count:'Count',
		batchNo:'Batch No',
		batch:'Batch',
		batchProductCount:'Actual Arrival Quantity',
		productCount:'SKU Count',
		pcsCount:'PCS',
		actualCarton:'Actual Carton',
		uploadFBAPDF:'Upload PDF',
		companyId:'Company ID',
		
		// 商品信息
		productInfo:'Product Info',
		clientProductCode:'Client Product Code',
		productCode:'Product Code',
		productName:"Product Name",
		productCnName:'Cn Name',
		productEnName:'En Name',
		productReviewFlag:'Is Reviewed',
		productReviewed:'Reviewed',
		productUnreviewed:'Unreviewed',
		productQuantity:'Product Quantity',
		orderQuantity:'Order Quantity',
		appointmentQuantity:'Appointment Quantity',
		productPrice:'Price',
		productLength:'Length',
		productBreadth:'Breadth',
		productHeight:'Height',
		productSpec:'Specifications',
		unit:'Unit',
		barcode:'Barcode',
		
		skuFile:'Sku File',
		uploadFileName:'Upload File',
		uploadSkuFileName:'Upload Sku File',
		fileLimitExcel:'Only xls/xlsx Files',
		fbxFileTips:'Upload FBX File-Only PDF Files',
		labelFileTips:'Upload Delivery File-Only PDF Files',
		deliveryFileTips:'Upload Delivery File-Only PDF Files',
		barcodeFileTips:'Client And WH Barcode-Only PDF Files',
		newLabelFileTips:'Upload New Label File-Only PDF Files',
		newLabelFileNolimitTips:'Upload New Label File',
		pdfFileTips:'Upload PDF File-Only PDF Files',
		skuFileTips:'Upload SkuList File-Only xls/xlsx Files',
		sortSkuFileTips:'Upload Sorting SkuList File-Only xls/xlsx Files',
		downloadTemplate:'Download Template',
		
		numberAndLetterLimit:'Only numbers and letters can be entered',	
		numberLimit:'Only numbers and letters can be entered、-、_',	
		numberToFixedOneLimit:'Please enter a number,One decimal place can be reserved',
		numberToFixedTwoLimit:'Please enter a number,Two decimal place can be reserved',
		positiveIntegerLimit:'Please enter positive integer',

		FBATips:'12 digit FBA No starting with FBA',
		FBAExample:' for example: FBA15G95TKZV',
		
		productionStatusOne:'Registered',
		productionStatusTwo:'Assign location',
		productionStatusThree:'Scheduled warehousing',
		productionStatusFour:'Warehoused',
		
		// RTO
		destory:'Destory',
		destorying:'Destorying',
		destoryCompleted:'Destory Completed',
		inventory:'Invertory',
		inventorying:'Inventorying',
		inventoryCompleted:'Inventory Completed',
		transfer:'Transfer',
		skuStock:'Skut Stock',
		waitForOrder:'Waiting for operation instructions',
		processing:' Processing',
		completed:' Completed',
		
		// 共用 窗口信息
		dialogBtnConfirm:'Confirm',
		dialogBtnSubmit:'Submit',
		dialogBtnSubmiting:'Submiting',
		dialogBtnCancel:'Cancel',
		dialogBtnClose:'Close',
		dialogBtnModify:'Modify',
		dialogBtnReset:'Reset',

		cancelProductCount:'cancel Product Count',

		transportType:'Transport Type',
		operationTime:'Operation Time',
		air:'Air',
		sea:'Sea',
		local:'Local',
		
	},
	// 系统设置 /menu.vue
	setting:{
		languageType:'English',
		changePasswordOption:'Change Password',
		loginOutOption:'Login Out',
	},
	// 菜单列表 /menu.vue
	menuItem:{
		homeItem:'Home',
		airOrderItem:{
			name:'Air Order',
			b2bOrder:'B2B Order',
			b2cOrder:'B2C Order',
		},
		seaOrderItem:{
			name:'Sea Order',
			bulkCargoOrder:'Bulk Cargo',
			
		},
		platformOrderItem:{
			name:'Platform Order',
			aliExpress:'AliExpress',
		},
		chinaWhItem:{
			name:'China WH',
			b2cOrder:'B2C Order',
			b2bOrder:'B2B Order',
		},
		ksaWhItem:{
			name:'KSA WH',
			product:'Product',
			batch:'Batch',
			inventory:'Inventory',
			inscanLog:'Inscan Log',
			outscanLog:'Outscan Log',
			fbxOrder:'FBX Order',
			b2cOrder:'B2C Order',
			inscanLogs:'Inscan Log',
			outscanLogs:'Outscan Log',
			operationLogs:'Operation Log',
			inboundLogs:'Inbound Logs',
			outboundLogs:'Outbound Logs',
		},
		rtoItem:{
			name:'RTO',
			rtoB2cOrder:'RTO B2C',
			rtoFbxOrder:'RTO FBX',
			rtoDamage:'RTO Damage',
		},
		trackingItem:'Tracking',
		clientInfoItem:'Client Info',
		templateDownloadItem:'Template Download',
	},
	//登录页面 /login.vue
	loginPage:{
		loginName_ph:'Please Input Username',
		loginPwd_ph:'Please Input Password',
		loginErrorInfo:'Wrong Username or Password,Please re-enter',
		loginSuccessInfo:'Login Success',
		loginOtherError:'Please Input The Correct Username And Password',
		loginBtn:'Login',
		systemPoweredBy:'Powered By',
	},
	// 首页页面 /home.vue
	homePage:{
		homeTitle:'Newest Notice',
		homeUpdateTime:'Release Time',
	},
	// 修改密码 /B2B/changePass.vue
	changePassPage:{
		changePassTitle:'Change Password',
		oldPassword:'Old Password',
		newPassword:'New Password',
		confirmPassword:'Confirm Password',
		confirmPassword_ph:'Please Enter The New Password Again',
		sameOldAndNewPasswordTips:'The New Password Cannot Be The Same As The Old Password!',
		diffNewPasswordTips:'Inconsistent with the new password!',
		newPasswordLimit:'Please Enter 5 To 16 Bits',
		cancelAndReload:'The Modification Has Been Canceled. Please Login Again',
		nullPasswordTips:'New And Old Passwords Cannot Be Empty',
	},
	// 客户信息
	clientInfoPage:{
		pageTitle:'Client Info',
		clientFullName:'ClientFullName',
		clientShortName:'ClientShortName',
		clientEmail:'Email',
		clientPrefixNo:'PrefixAwbNo',
		wcServiceAccount:'Fleetan Wechat Service Account',
		clickAndCopy:'Click to copy',
	},
	// 模板下载页面
	mouldListPage:{
		pageTitle:'Template Download List',
		templateName:'Name',
		templateUrl:'Url',
	},
	// 平台订单
	platformOrder:{
		uploadOrderList:'Import Order List',
		aliExpressList:'AliExpress Order List',
	},
	// 中国仓库 B2C订单页面 /B2COrderList.vue
	chinaWhB2COrderPage:{
		listTabName:'List',
		B2ClistTabName:'B2C List',
		listTabSearchItemAWB_ph:'FLEETAN AWB',
		listTabSearchItemPaymentMode_ph:'Payment Mode',
		listTabOperationMultipleDownloadAwb:'Batch Download AWB',
		//表格信息
		listTabTableColumnAwb:'Dlvd AWB',
		listTabTableColumnImileAwb:'Imile AWB',
		listTabTableColumnDestCity:'DLVD Dest City',
		listTabTableColumnPaymentMode:'Payment Mode',
		// 表格操作
		listTabTableActionDownloadAwb:'AWB',
		downloadNoodles:'AWB',
		listTabTableActionMessage:'Message',
		messageInformation:'Message',
		listTabTableActionWeight:'Check Weight',
		squareWeighing:'Check Weight',
		// 轨迹查询窗口
		dialogCheckTrackTitle:'DLVD Tracking',
		dialogCheckTrackNoData:'No trace information yet',
		//订单详情窗口
		dialogDetailOrderInfo:'Order Info',
		dialogDetailAmountToCollect:'Amount To Collect',
		dialogDetailProductDes:'Product Description',
		dialogDetailConsignorInfo:'Consignor Info',
		dialogDetailConsigneeInfo:'Consignee Info',
		dialogDetailProductDetail:'Product Details',
		dialogDetailProduct_productName:'Product Name',
		dialogDetailProduct_productLink:'Product Link',
		dialogDetailProduct_productHscode:'Product Hscode',
		dialogDetailProduct_productSku:'Product SKU',
		dialogDetailProduct_productPcs:'Pcs',
		dialogDetailProduct_productPrice:'Product Price',
		dialogDetailProduct_productTaxrate:'Product Taxrate',
		// 留言板窗口
		dialogMessageTitle:'Message Board',
		dialogMessageInputMessage:'Please Input Message',
		dialogMessageSubmit:'Release',
		dialogMessageSubmitSuccess:'Release Success',
		// 重量信息窗口
		dialogWeightTitle:'Weight Info',
		
		// 导入Tab页
		importTabName:'Import Order',
		B2CimportTabName:'B2C Import Order',
		importTabImportINTBtn:'Import INT Order',
		importTabImportDOMBtn:'Import DOM Order',
		importTabImportCDOMBtn:'Import CDOM Order',
		importTabImportFDOMBtn:'Import FDOM Order',
		importTabDownloadTemplateBtn:'Download Template',
		downloadINTTemplateBtn:'Download INT Template',
		downloadFDOMTemplateBtn:'Download FDOM Template',
		importTabTableColumnImportTime:'Imp Time',
		importTabTableColumnOperator:'Operator',
		importTabTableColumnOrderCount:'Order Count',
		importTabTableColumnAction:'DLVD AWB Sheet',
		importTabTableColumnActionExportOrder:'Export Order',
		importTabExportDataTips:'Confirm To Export The Selected Data',
	},
	// 中国仓库 B2B订单页面 /B2BOrderList.vue
	chinaWhB2BOrderPage:{
		pageTitle:'B2B Order List',
		actionCheckPackage:'Check Package',
		actionModifyFBA:'Modify FBA',
		officialCD:'Official',
		unofficialCD:'Unofficial',
		generated:'Generated',
		boxes:'boxes',
		expandAndHide:'click to expand/hide',
		
		//确认重量窗口
		dialogPackageInfoTitle:'Package Info',
		// 转单小包包裹信息窗口
		dialogPacketInfoTitle:'Packet Info',
		dialogPacketInfoPacketNo:'Packet No',
		dialogPacketInfoLink:'Link',
		dialogPacketInfoResult:'Result',
		customsDeclaration:'Customs Declaration',
		customsDeclarationFlag:'Customs Declaration',
		// 添加品名窗口
		dialogAddKindnameTitle:'Add Production',
		dialogAddKindname:'Production',
		dialogAddKindnameSubmitTips:'Please Input Production Name',
		kindnameDes:'Production Describe',
		kindnameSelectTips:'Please Choose The Frequently-used Production',
		// 修改FBA窗口
		dialogModifyFBANewFBAInfo:'New FBA Info',
		dialogModifyFBANewFBATips:'Please Upload New FBA File',
		szDlvdWarehouseAddress:'Shenzhen FlEETAN Warehouse Address',
		szDlvdWarehouseContact:'Mr Zhang',
		orderReviewTips:'Order Under Review，Please wait for the approval, download the face sheet and paste it as required before shipping',
		orderSkuCountTips:'The total quantity of products shipped needs to be accurate. FBA needs this quantity to make an appointment to deliver to the warehouse',
		clientOrderPackageAmount:'Client Order Boxes',
		createBoxNoTips:'Enter A Number To Generate The Box Info',
		downloadPdfFailedTips:'PDF File is being generated. Please refresh the list later and download it',
		createClientPackageNoInputFBATips:'Please Input FBA No First, The Package No Is Generated According To FBA No',
		createClientPackageNoInputASNTips:'Please Input ASN No First, The Package No Is Generated According To ASN No',
		submitOrderProductionTips:'Please Choose Or Add Production',
		submitOrderInputPackageNumTips:'Package Count Or Declared Amount Cannot Be Negative',
		submitOrderInputSkuNumberTips:'Sku Count Or Declared Amount Cannot Be Negative',
		submitOrderInputClientPackageNumTips:'Please Input Client Order Boxes',
		uploadPacketFileDiffNumTips:'The number of packets is inconsistent with package count you filled in, Please check',   
		
		generateBoxStickerTipsPre:'The box sticker is still being generated, but the generation time is too long,You can close the window and wait for',
		generateBoxStickerTipsNext:'minute,and then refresh the list and download the box sticker',
		deliveryDateTips:'Please Select Appointment Time，Consistent with PDF files Time',
																						
		caseAutoFillTips:'Auto Fill Case Info To Subsequent Case',
		caseInputTips:'Detected that the Carton number information is not filled in. Please complete it',
		Approved:'Approved',
		underReview:'Under Review',
		clientOrderNo:'Client OrderNo',
		
		// 海运订单多目的地信息
		destInfo:'Dest Info',
		seaOrderDestTitle:'Order Dest Info',
		destType:'Dest Type',
		clearAllDestInfoTips:'This operation will clear all destination information. Are you sure?',
		seaCartonIndex:'Delivery Carton Index',
		cartonIndexTips:'Please Input Carton Index Delivery Dest',
		cartonIndexStart:'Carton Index Start',
		cartonIndexEnd:'Carton Index End',
		errorCartonIndex:'Carton index start cannot be greater than carton index end',
		overCartonAmountTips:'Carton index cannot be over carton amount',
		inputCartonIndexTips:'Please input carton index,for example: 1-5,7,9-10,12',
		inputCartonIndexNumTips:'Carton index area start cant be over end',
		repeatCartonTips:'Order destination information. Some boxes have multiple delivery addresses, please check',
		cartonTotalPcs:'Total PCS',
		cartonTotalPcsTips:'Please enter the total PCS included in the destination delivery box',
		
		seaOrderFailedReason:'Sea Order Failed Detail',
		
	  //分批轨迹
	  batchTrackingTitle:'Batch Info',
		
		containWoodOrBamboo:'Contain wood or bamboo',
		shipType:'Ship Type',
		normalShip:'Ordinary',
		fastShip:'Fast',
		shipTypeTips:'The price of fast ships is higher than that of Ordinary ships. Please consult the salesperson for specific prices',
	},
	// 沙特仓库，商品列表
	ksaWhProductListPage:{
		pageTitle:'Product List',
		exportProductDataBtn:'Export Product Data',
		multipleImportProductDataBtn:'Mutiple Import Product',
		dialogProductAddTitle:'Add Product',
		dialogProductModifyTitle:'Modify Product',
		exportAllProductTips:'Export All Product Data',
		productUploadSuccessTips:'Import success, system is reviewing and parsing product, please wait.',
		productUploadSuccessTips_title:'Notes on importing product templates: ',
		productUploadSuccessTips_opt1:'1、Image is not mandatory.',
		productUploadSuccessTips_opt2:'2、Images should not be embedded or exceed cells',
		productAnalysis:'Reviewing...',
		productAnalysisFailed:'Reviewing failed',
	},
	// 沙特仓库，批次列表
	ksaWhBatchListPage:{
		pageTitle:'Batch List',
		orderNoOrBatchNo:'Order No(Batch No)',
		turnToSkuStock:'Turn To Sku Stock',
		skuStockDes:'If the order requires our overseas warehouse to perform on the shelf, or the goods delivered to NOON are not delivered at one time for a whole order, Select this type',
		cartonStockDes:'1.If the FBA number cannot be known before Create order due to insufficient FBA storage capacity, 2. The ASN number cannot be created before Create order for FBN, but it can be clear that the order is a one-time delivery of the whole order',
		turnToSkuTipOne:'Tips:This operation will convert',
		turnToSkuTipTwo:' All ',
		turnToSkuTipThree:'Carton Stock of this batch to SKU Stock, and partial conversion is not supported',
	},
	// 沙特仓库，库存页面
	ksaWhInventoryPage:{
		tabOneTitle:'Stat',
		tabOnePageTitle:'Data Statistics',
		cartonInfo:'Carton Info',
		currentCartonOrderCount:'Current Carton Order Count',
		currentCartonCount:'Current Carton Inventory Count',
		skuInfo:'Sku Info',
		currentSkuCount:'Current Sku Inventory Count',
		currentProductCount:'Current Product Inventory Count',
		
		tabTwoTitle:'Product',
		tabTwoPageTitle:'Product Inventory List',
		exportAllSkuData:'Export All Sku Inventory Data',
		
		tabThreeTitle:'Carton',
		tabThreePageTitle:'Carton Inventory List',
		exportCurrentAllCartonInventoryData:'Export Current All Carton Inventory Data',
		putOnTime:'InitTime',
		storageTime:'Storage Time(Day)',
	},
	// 沙特仓库 商品入库记录列表
	ksaWhInscanLogs:{
		pageCartonTitle:'Carton Inscan Logs List',
		pageCartonTitle2:'Carton Inbound Logs List',
	},
	// 沙特仓库 商品出库记录列表
	ksaWhOutscanLogs:{
		pageCartonTitle:'Carton Outscan Logs List',
		pageCartonTitle2:'Carton Outbound Logs List',
	},
	// 沙特仓库 上架日志
	ksaWhInboundLogs:{
		tabOne:'Sku',
		tabOneTitle:'Product Inscan Logs',
		tabOneTitle2:'Product Inbound Logs',
		inboundTime:'Inbound Time',
	},
	// 沙特仓库 下架日志
	ksaWhOutboundLogs:{
		tabOne:'Sku',
		tabOneTitle:'Product Outscan Logs',
		tabOneTitle2:'Product Outbound Logs',
		outboundTime:'Outbound Time',
	},
	// 沙特仓库 FBX订单列表页面
	ksaWhFBXOrderPage:{
		multipleImportProduct:'Multiple Import Product',
		inputCorrectQuantity:'Please Input Correct Quantity',
		newLabelCode:'New Label Code',
		newLabelQuantity:'New Label Quantity',
		newSku:'New Sku',
		newSkuCount:'New Sku Count',
		cancelNumber:'Cancel Count',
		newLabelFileInfo:'New Label File Info',
		createOrderInfo:'Create Order Info',
		modifyOrderInfo:'Modify Order Info',
		submitDeliveryTimeTips:'Is Confirm that Delivery Time: ',
		checkProductTips:'Add product information and detect whether there are no entered products or duplicate products. Please check whether the list products are missing',
		deleteOrderTips:'Delete Order?',
		uploadPreTips:'Please Upload ',
		selectProductTips:'Please Select Product First',
		overOrderCountTips:'The order quantity cannot exceed the inventory quantity of the goods',
		orderCountDiffTips:'It is detected that the total quantity of ordered goods is inconsistent with the expected delivery quantity',
		orderCountTips:'Total quantity of goods',
		confirmToCreateOrderTips:'Confirm whether to continue to create the order',
		checkProductErrorTips:'No product information detected',
	},
	// RTO B2C /RTO/RtoBind.vue
	rtoB2COrderPage:{
		tabOne:'Pending',
		tabOnePageTitle:'RTO B2C List',
		tabTwo:'Completed',
		
		multipleTransfer:'Multiple Transfer',
		multipleInventory:'Multiple Inventory',
		downloadTransferTemplate:'Download Transfer Template',
		multipleDestory:'Multiple Destory',
		remainingDay:'Remaining Operation Time(Day)',
		operationTips:'If the storage time exceeds 30 days, the system will automatically destroy the current order',
		operationOverTime:'Time out and destroyed',
		packetTransfer:'Packet Transfer',
		confirmDestoryOrder:'Is confirm to Destory Order ?',
		confirmMultipleDestoryOrder:'Is confirm to execute batch destruction?',
		urgentOrderTip:'This order is urgent，Urgent fee required',
		confirmInventoryOrder:'Is confirm to inventory product info?',
		transferInfo:'Transfer Info',
		originOrderInfo:'Original Order Info',
		confirmToTransferOrder:'Is confirm to transfer order?',
	},
	// RTO FBX /RTO/RtoFbx.vue
	rtoFbxOrderPage:{
		tabOne:'QC Passed',
		tabOnePageTitle:'FBX QC Passed SKU List',
		tabTwo:'QC Failed',
		tabTwoPageTitle:'FBX QC Failed SKU List',
		tabThree:'Completed',
		
		rtoCartonNo:'RTO Carton No',
		
		qcFailedProductInfo:'QC Failed SKU Info',
		skuLabelDamage:'SKU Damaged',
		changeSku:'Change',
		packageDamage:'Package Damaged',
		changeOrigin:'Origin',
		changePO:'PO',
		productDamage:'Product Damaged',
		label:'Label',
		uploadLabel:'Upload Label',
		downloadLabel:'Download',
		
		crossTime:'Cross Time',
		instructionTime:'Instruction Time',
		completeTime:'Complete Time',
		crossCheck:'Cross Check',
		transferWh:'Transfer WH',
		unpackingList:'Unpacking List',
		checkPhoto:'Check Picture',
		multipleTransferWh:'Multiple Transfer WH',
		multipleCrossCheck:'Multiple Cross Check',
		multipleStockSku:'Multiple Inventory',
		transferWhInfo:'Transfer Warehouse Info',
		crossCheckSkuInfo:'Cross Check Sku Info',
		qcQualified:'QC Qualified',
		qcUnQualified:'QC Unqualified',
		stockAll:'Inventory All',
		destoryAll:'Destory All',
		operationType:'Operation Type',
		crossCheckCompleted:'Cross Check Completed,Waiting for instructions',
		crossCheckNewTask:'Wait for the warehouse to perform the unpacking list instruction operation',
		crossCheckAllCompleted:'Cross Check Operation is all completed',
		crossCheckTips:'Is confirm to Cross Check Order?',
		checkAllSkuSelected:'Please confirm all Sku selected',
		qcFailedSkuTips:'QC Unqualified SKU And Count',
		multipleInventoryTips:'This operation will Inventory the Selected Carton SKU. Are you sure',
		
		inventoryTips:'QC unqualified SKU need to check the pictures before inventory. QC qualified SKU will be stock to the normal inventory, and QC unqualified SKU will be stock to the damaged inventory',
		hasQcFailedRowTips:'It is detected that there are no sku info or has QC unqualified SKU in the Selected carton, and the batch Inventory operation cannot be performed. Please perform the destruction or shelving operation in the unpacking list',
		isCompletedCrossCheckRowTips:'Please selected carton that has not completed the cross check subsequent operation',
		
		submitQcPassedDataTips:'Please check the information of each product and issue operating instructions',
		submitQcFailedDataTips:'Please check the information of each product and issue operating instructions,Part of them need to upload new label',
		checkStatusTips:'Please select row which waiting for instructions',
		
		selectStatusTips:'Please Select Status',
		crossCheckTimeStartTips:'CrossCheck Start',
		crossCheckTimeEndTips:'CrossCheck End',
		instructionTimeStartTips:'Instruction Time Start',
		instructionTimeEndTips:'Instruction Time End',
	},
	// RTO Damage /RTO/RtoDamage.vue
	rtoDamagePage:{
		pageTitle:'RTO Damage List',
	},
	//DLVD
	trackQuery:{
		trackTitle:'Tracking',
		trackContent:'Input Order Number',
		trackButton:'Track',
		NotExist:'The order number entered does not exist',
		messageImport:'Input Order Number'
	},
	//B2BStatus
	B2BStatus:{
		creating:'CREATING',
		init:'INIT',
		warehousing:'WAREHOUSING',
		cnReceive:'CN RECEIVE',
		cnPartialShipment:'CN PARTIAL SHIPMENT',
		cnOutscan:'CN OUTSCAN',
		cnOutscanExceptionPart:'CN OUTSCAN EXCEPTION PART',
		cnTransitExceptionCheck:'CN TRANSIT EXCEPTION CHECK',
		cnAir:'CN AIR',
		cnTransitException:'CN TRANSIT EXCEPTION',
		cnTransitExceptionOr:'CN TRANSIT EXCEPTION OR',
		cnAirExceptionDelay:'CN AIR EXCEPTION DELAY',
		cnAirExceptionCancel:'CN AIR EXCEPTION CANCEL',
		airArrived:'AIR ARRIVED',
		customsClearanceCompLated:'CUSTOMS CLEARANCE COMPLATED',
		customsClearanceComplatedExceptionCheck:'CUSTOMS CLEARANCE COMPLATED EXCEPTION CHECK',
		customsClearanceComplatedExceptionDeduction:'CUSTOMS CLEARANCE COMPLATED EXCEPTION DEDUCTION',
		overseasArrived:'OVERSEAS ARRIVED',
		ksaInscan:'KSA INSCAN',
		cartonToSku:'CARTON TO SKU',
		ksaReadyForDispatch:'KSA READY FOR DISPATCH',
		delivering:'DELIVERING',
		deliveryFailed:'DELIVERY FAILED',
		partialreceipt:'PARTIAL RECEIPT',
		successfulDelivery:'SUCCESSFUL DELIVERY',
		signingFailed:'SIGNING FAILED',
		cancelOrder:'CANCEL ORDER',
		transferThirdpartyDelivery:'TRANSFER THIRDPARTY DELIVERY'
	},
	//B2CStatus
	B2CStatus:{
		returned:'Returned',
		delivered:'Delivered',
		pending:'Pending'
	},

	//B2BOrder
	B2BOrder:{
		airTransportOrder:'Air Order',
		airTransportOrderList:'Air Order List',
		oceanShippingOrder:'Sea Order',
		oceanShippingOrderList:'Sea Order List',
		customerAwbNo:'Client WH EnteringNo',
		deCountry:'Dest Country',
		deType:'Service Type',
		goodCnDesc:'Good Cn Desc',
		goodEnDesc:'Good En Desc',
		cnConsignorWh:'Cn Consignor WH',
		goodType:'Good Type',
		examineStatus:'Examine Status',
		cartonInfo:'Carton Info',
		orderNoRepeat:'Client Order No Repeat',
		succeededOceanOrder:'Succeeded In Modifying B2B Ocean Shipping Order',
		addOceanOrder:'Successfully Added B2B Ocean Shipping Order',
	},
	//出入库日志
	LogManagement:{
		inscanLogList:'Inscan Log List',
		outscanLogList:"Outscan Log List",
		warehouseName:'Warehouse Name',
		cuBatchNumber:'Customer Batch No',
	}	
}
export default en;