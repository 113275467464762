import Vue  from 'vue' //引入vue
import VueI18n from 'vue-i18n';//引入国际化i18n模块
import ElementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en' //element-ui 英文包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' //element-ui 中文包
import DatePicker from 'element-ui/lib/date-picker'
import enLocale from './en' //本地英文包
import zhLocale from './zh' //本地中文包
import arLocale from './ar' //本地阿拉伯语包

Vue.use(VueI18n) //vue 使用i18n模块
Vue.use(DatePicker)

const message = {
	en:{
		templete:'hello',
		...enLocale,// es6拓展运算符，解析出每一个对象
		...elementEnLocale,
	},
	zh:{
		templete:'你好',
		...zhLocale,
		...elementZhLocale,
	},
	ar:{
		templete:'مرحبا',
		...arLocale,
		...elementEnLocale,//element 暂无阿拉伯语，使用英语
	}
}
// 浏览器默认语言 中文:zh-CN、英文:en、阿拉伯语:ar
let localLanguage = 'zh-CN';
switch(navigator.language){
	case 'zh-CN': localLanguage = 'zh'; break;
	case 'en': localLanguage = 'en'; break;
	case 'ar': localLanguage = 'ar'; break;
}

//创建国际化实例
const i18n = new VueI18n({
	//设置默认语言
	// 注：后期可能需要将该信息录入客户设置，需要和后台对接
	locale:localStorage.getItem('vipLang') || localLanguage, // 默认浏览器语言
	silentTranslationWarn:true,
	// fallbackLocale:'zh',//当语言不存在时使用默认语言
	messages:message,
})


export default i18n


// 切换语言
export function changeLanguage(lang){
	i18n.locale = lang;
	document.querySelector('html').setAttribute('lang',lang);
	// 设置阿拉伯语需要将页面内容左右翻转，当地人阅读顺序为从右往左
	if(lang == 'ar'){
		document.querySelector('html').setAttribute('dir','rtl');
	}else{
		document.querySelector('html').removeAttribute('dir');
	}
}