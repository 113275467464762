<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
	import { changeLanguage } from '@/assets/i18n/index'
	export default {
		name:'App',
		mounted(){
			let lang = localStorage.getItem('vipLang');
			changeLanguage(lang);
		}
	}
</script>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.el-menu-item[data-v-77b09cf9] {
  text-align: left !important;
}
</style>
